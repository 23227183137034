import React, { useState } from 'react'
import styles from './Intranet.module.css'
import Header from '../../components/Header/Header'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ModelAlert from '../../components/ModalAlert/ModelAlert'
import { IoIosGlobe } from "react-icons/io";
import { color } from 'hooks/Utils/color'

const intranetList = [
    {
        title: 'Sénat',
        description: 'Site officiel du Sénat de la République du Congo.',
        link: 'https://www.senat.cg/'
    },
    {
        title: 'Gouvernement',
        description: 'Site officiel du Gouvernement de la République du Congo.',
        link: 'https://gouvernement.cg/'
    },
    {
        title: 'Primature',
        link: 'https://www.primature.gouv.cg/',
        description: 'Site officiel de la Primature de la République du Congo.',
    },
    {
        title: 'Secrétariat Général',
        link: 'https://www.sgg.cg/fr/accueil.html',
        description: 'Secrétariat Général du Gouvernement de la République du Congo.'
    }
]


const Intranet = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const [userLanguage, setUserLanguage] = useState(i18n.language)
    const navigate = useNavigate()

    const controller = new AbortController();
    const signal = controller.signal;

    const { data: authReducerState } = useAuthReducer()
    const [user, setUser] = useState(authReducerState?.user)

    const [showAlert, setShowAlert] = useState(false)


    // NAVIGATION HANDLE
    const goBack = () => navigate(-1)
    const handleShowAlert = () => setShowAlert(true)
    const goToRequest = () => navigate('/intranet/request')
    const goToHolidayBalance = () => navigate('/intranet/holiday-balance')
    const goToEvents = () => navigate('/intranet/events')
    const goToCalendar = () => navigate('/intranet/calendar')
    const goToSharedDocs = (link) => {
        window.open(link, "_blank", "noreferrer");
    }



    const goToAIAssistant = () => navigate('/intranet/ai-assistant')

    return (
        <div className={styles.screen}>
            <Header title='Intranet AfDB' goBack={goBack} />
            <div className={styles.container}>

                {intranetList?.map((data, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => goToSharedDocs(data?.link)}
                            className={styles.pressable}>
                            <IoIosGlobe size={40} color={color.info} style={{ marginRight: '15px' }} />
                            <div>
                                <h6 className={styles.titlePressable}>{data?.title}</h6>
                                <p className={styles.descPressable}>{data?.description}</p>
                            </div>
                        </div>
                    )
                })}

                <div onClick={goToRequest} className={styles.pressable}>
                    <img src={require('../../assets/icons/rh_request_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myRequests')}</h6>
                        <p className={styles.descPressable}>{t('myRequestDesc')}</p>
                    </div>
                </div>

                <div onClick={goToHolidayBalance} className={styles.pressable}>
                    <img src={require('../../assets/icons/balance_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myBalance')}</h6>
                        <p className={styles.descPressable}>{t('myBalanceDesc')}</p>
                    </div>
                </div>

                <div onClick={goToEvents} className={styles.pressable}>
                    <img src={require('../../assets/icons/event_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myEvents')}</h6>
                        <p className={styles.descPressable}>{t('myEventDesc')}</p>
                    </div>
                </div>

                <div onClick={goToCalendar} className={styles.pressable}>
                    <img src={require('../../assets/icons/calendar_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myCalendar')}</h6>
                        <p className={styles.descPressable}>{t('myCalendarDesc')}</p>
                    </div>
                </div>

                <div onClick={goToSharedDocs} className={styles.pressable}>
                    <img src={require('../../assets/icons/doc_share_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>
                            Intranet
                        </h6>
                        <p className={styles.descPressable}>
                            {t('sharedDocumentDesc')}
                        </p>
                    </div>
                </div>

                <div onClick={goToAIAssistant} className={styles.pressable}>
                    <img src={require('../../assets/icons/chat_gpt.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myAIAssistant')}</h6>
                        <p className={styles.descPressable}>{t('myAIAssistantDesc')}</p>
                    </div>
                </div>


                <div onClick={goToAIAssistant} className={styles.pressable}>
                    <img src={require('../../assets/images/icon_google_meet.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>Google Meet</h6>
                        <p className={styles.descPressable}>{t('myAIAssistantDesc')}</p>
                    </div>
                </div>


                <div
                    onClick={() => window.open('https://nakani.info', "_self")}
                    className={styles.pressable}>
                    <img src={require('assets/images/icon-nakani.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>
                            Nakani Artificial Intelligence
                        </h6>
                        <p className={styles.descPressable}>
                            {i18n.language == 'en'
                                ? 'Explore the potential of African companies with confidence using our cutting-edge technology chatbot !'
                                : 'Explorez le potentiel des entreprises africaines en toute confiance avec notre chatbot à l\'avant-garde de la technologie !'
                            }
                        </p>
                    </div>
                </div>

            </div>


            <ModelAlert
                message={userLanguage == 'fr' ? 'Bientôt disponible.' : 'coming soon.'}
                show={showAlert}
                setShow={setShowAlert}
            />

        </div>
    )

}

export default Intranet

